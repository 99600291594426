





























import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class NotFound extends Vue {
  @Prop() props!: any;
  public msg = '';

  created() {
    if (this.props.message) {
      this.msg = this.props.message;
    }
  }
}
